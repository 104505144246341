<template>
  <div class="product-wrapper">
    <div class="product-nav">
      <div class="text">
        {{ $t('product') }}
        <div class="line"></div>
      </div>
    </div>
    <div class="router">
      <router-view />
    </div>
  </div>
</template>
<script>
import { configLink } from '@/utils'
export default {
  name: 'Product',
   metaInfo() {
    return {
      title: this.$t('product')
    }
  },
  data() {
    return {
      navLink: [...configLink[0].list]
    }
  },
}
</script>

<style lang="scss" scoped>
.product-wrapper {
  position: relative;
  width: 100%;
  .product-nav {
    width: 100%;
    height: 160px;
    background: url('~@/assets/image/product/nav.jpg') no-repeat;
    background-size: 100% 100%;
    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
  .product-nav-link {
    border-bottom: 1px solid rgba(225, 225, 225, 1);
    position: sticky;
    top: 70px;
    z-index: 10;
    background: #fff;
    &>div {
      width: 100%;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      .product-nav-link-router {
        margin: 0 24px;
        color: rgba(136, 136, 136, 1);
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: color 0.3s;
        &.router-link-exact-active, &:hover {
          transition: color 0.3s;
          color: rgba(34, 34, 34, 1);
        }
      }
    }
  }
}
</style>
